// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  server: 'lts-staging.iowaregents.edu/',
  protocol: 'https://',
  apiUrl: "https://lts-staging.iowaregents.edu/lts/",
  casesEndpoint: "cases",
  listDatatEndpoint: "lists",
  institutionsEndpoint: "institutions",
  attorneysEndpoint: "users/inhouseattorneys",
  detailsEndpoint: "details",
  notesEndpoint: "notes",
  currentUserEndpoint: "users/currentuser",
  attachmentsEndpoint: "attachments",
  shibboletEndpoint: 'Shibboleth.sso/',
  logoutEndpoint: 'Logout',
  usersEndpoint: 'users',
  borDiscoSearch: 'uiowa'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
